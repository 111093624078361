@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$header-height: 70px !default;
$auth-header-background-color: white !default;
$header-background-color: white !default;
$auth-header-text-color: $black !default;
$header-text-color: $black !default;

.home-header {
	height: $header-height;
	z-index: 2;
	border: none;
	background-color: $header-background-color;
	color: $header-text-color;
	width: 100vw;

	&--hidden {
		display: none;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 8px;
		height: 100%;

		@include gridle_state(md) {
			padding: 0 40px;
		}
	}

	&__left {
		width: 60px;

		@include gridle_state(md) {
			display: none;

			&--visible {
				display: block;

				svg {
					width: 30px;
					height: 30px;
					opacity: 0.4;
				}
			}
		}
	}

	&__brand {
		width: 60%;
		display: flex;
		align-items: center;
		justify-content: center;

		.logo-brand {
			width: 100%;
		}

		.relative-link {
			height: 100%;
			width: 100%;

			&__text {
				height: 100%;
				width: 100%;
			}
		}

		@include gridle_state(md) {
			width: 200px;
			justify-content: flex-start;
		}
	}

	&__shop {
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include gridle_state(md) {
			align-self: flex-start;
		}
	}

	@include gridle_state(md) {
		background: $auth-header-background-color;
		color: $auth-header-text-color;
	}

	.back-button {
		color: white;
	}

	&--translucent {
		width: 100vw;
		background-color: transparent !important;
		padding-top: 10px;

		@include gridle_state(md) {
			padding-top: 30px;
		}

		.logo-brand {
			height: 60px;
		}

		&__shop {
			color: white;
		}
	}

	.shop-switcher {
		&__button {
			.icon:first-of-type {
				width: 20px;
				height: 20px;

				@include gridle_state(md) {
					width: 30px;
					height: 30px;
				}
			}
		}
	}
}
