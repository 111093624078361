@font-face {
	font-family: "nexa";
	font-display: swap;
	src: url("/static/font/nexa/Nexa_Regular-webfont.woff2") format("woff2"),
		url("/static/font/nexa/Nexa_Regular-webfont.woff") format("woff");
}

@font-face {
	font-family: "nexa-bold";
	font-display: swap;
	src: url("/static/font/nexa/Nexa_XBold-webfont.woff2") format("woff2"),
		url("/static/font/nexa/Nexa_XBold-webfont.woff") format("woff");
}
