@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.shop-switcher {
	&__button {
		cursor: pointer;
		display: flex;
		align-items: center;

		.icon:first-of-type {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}
	}

	&__body {
		background: white;
		min-width: 150px;
		padding: 10px 10px 0;
		border-radius: $border-radius-big;
		z-index: 10;

		.shop-list {
			&__item {
				@extend %text-bold;

				color: $black;
				display: flex;
				align-items: center;
				height: 40px;
				padding: 15px 10px 10px 0;
				cursor: pointer;
				border-bottom: 1px solid $grey-medium;

				&:last-of-type {
					border-bottom: none;
				}

				.icon {
					width: 25px;
					height: 25px;
					margin-right: 10px;
					transition: transform 0.3s ease;
				}

				&:hover {
					.icon {
						transform: scale(1.2, 1.2);
					}
				}
			}
		}
	}
}
